import './chatbot.css'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useRef, useEffect,useContext } from 'react';
import { WebchatContext } from './Chatbot'

function ChatbotContentAnswser({keyid, body}){

    const data = useContext(WebchatContext)
    console.log(data);

    //https://stackoverflow.com/questions/75684154/how-to-always-scroll-into-view-for-particular-div-element-in-react-js
    const divRef = useRef();

    const scrollToElement = () => {
        const {current} = divRef
        if (current !== null){
            current.scrollIntoView({behavior: "smooth"})
        }
    }
    useEffect(scrollToElement, [])

    const body_response = body.split('\n')

    return(<div ref={divRef} className='ChatbotContent__answer_wrapper'>
    {/* chat icon */}
    <AccountCircleIcon color="primary" sx={{fontSize: 40}}></AccountCircleIcon>
    {/* chat body */}
    <div className='ChatbotContent___answer_chatblock'>
        <div className='ChatbotContent__chatbody'>
            {body_response.map((line,key)=>{
                return <div key={key} className='ChatbotContent__chatreply'>{line}</div>;
            })}
        </div>
    </div>
</div>)
}

export default ChatbotContentAnswser