import './chatbot.css'; 

function ChatbotContentQuestion({body}){
    return( <div className='ChatbotContent__question_wrapper'>
    {/* chat body */}
    <div className='ChatbotContent___question_chatblock'>
        <div className='ChatbotContent__chatbody'>
            {body}
        </div>
    </div>
</div>)
}

export default ChatbotContentQuestion