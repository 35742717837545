import { useReducer } from "react";
import webchatreducer from "./webchatreducer";

const webchatInitialState = {
    isWebchatOpen: false,
    welcomeMessage: `Hello User, I'm your personal assistant\n If you have a question or topic you'd like to discuss let me know i'm hear to assist.`
}

export default function useWebchat(){
    const [webchatState, webchatDispatch] = useReducer(webchatreducer,webchatInitialState);

    return {
        webchatState,
        webchatDispatch
    }

}