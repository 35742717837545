import './App.css';
import Homepage from './Homepage/Homepage';

function App() {
  return (
  <Homepage></Homepage>
  );
}

export default App;
