import SmartToyIcon from '@mui/icons-material/SmartToy';
import './chatbot.css'; 
import ChatbotWebView from './ChatbotWebview';
import useWebchat  from './util/webchathook'
import { createContext } from 'react';

export const WebchatContext = new createContext();

function Chatbot(){
    const  { webchatState,webchatDispatch } = useWebchat();
    return(<>
    {webchatState.isWebchatOpen ? 
    <WebchatContext.Provider 
        value={{
            webchatState,
            webchatDispatch
        }}>
        <ChatbotWebView/>
        {/* <ChatbotWebView change={webchatDispatch}/> */}
    </WebchatContext.Provider>
    : 
    <div onClick={()=>webchatDispatch({type: 'webchatisopen'})} className='Chatbot__icon'>
    <SmartToyIcon sx={{fontSize: 50}}></SmartToyIcon>
    </div>}
    </>)
}

export default Chatbot