export default function webchatreducer(
    state,
    action ){
    switch(action.type){
        case 'webchatisopen':{
            return {...state, isWebchatOpen : true}
        }
        case 'webchatisclosed':{
            return {...state, isWebchatOpen : false}
        }
        default:
            return action
    }
}