import "./homepage.css";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function HomepageHearder() {
  return (
    <div className="HomepageHeader__main">
      <div className="HomepageHeader__wapper">
        <div className="HomepageHearder_Logo">Branding Chatbot</div>
        <div className="HomepageHearder_Options">
          <div className="HomepageHearder_Options2">
            <div className="HomepageHearder_Option">
              Product
              <KeyboardArrowDownIcon />
            </div>
            <div className="HomepageHearder_Option">
              Demos
              <KeyboardArrowDownIcon />{" "}
            </div>
            <div className="HomepageHearder_Option">
              Platform
              <KeyboardArrowDownIcon />
            </div>
            <div className="HomepageHearder_Option">
              Solutions
              <KeyboardArrowDownIcon />
            </div>
            <div className="HomepageHearder_Option">
              Customers
              <KeyboardArrowDownIcon />
            </div>
            <div className="HomepageHearder_Option">
              About
              <KeyboardArrowDownIcon />
            </div>
          </div>
        </div>
        <div className="HomepageHearder_search_wrapper">
          <div className="HomepageHearder_search_inputarea">
            {/* text area */}
            <input className="HomepageHearder_search_input" />
            {/* icon */}
            <SearchIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomepageHearder;
