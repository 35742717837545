import MinimizeIcon from '@mui/icons-material/Minimize';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SendIcon from '@mui/icons-material/Send';
import './chatbot.css'; 
import ChatbotContent from './ChatbotContent';
import { useEffect, useState ,useContext} from 'react';
import axios from 'axios';
import { WebchatContext } from './Chatbot'
import { gemini_api, get_url } from '../constants';

const chat_payload = { conversation: [
    {"role": "system","content": "You are a helpful assistant."},
]};

function ChatbotWebView(){
    
    const {webchatDispatch} = useContext(WebchatContext)

    const [query, setQuery] = useState('');
    const [payload, setPayload] = useState(chat_payload)

    function handleinputchange(value){
        console.log('hello')
        setQuery(value);
    }

    function handleinputsubmit(){
        if(query === ''){
            return;
        }
        setPayload((pay) => ({
            conversation : [
                ...pay.conversation,  
                {"role": "user", "content": query}
            ]
        }))
        setQuery('');
    }

    useEffect(()=>{
        console.log(payload.conversation[payload.conversation.length -1])
        if(payload.conversation[payload.conversation.length -1].role === 'user'){
            console.log("payload ", payload.conversation)
            axios.post(get_url,
                payload.conversation
            )
            .then((res)=>{
                console.log(res.data)
                console.log(res.data[0].message)
                setPayload((pay) => ({
                    conversation : [...pay.conversation, res.data[0].message]
                }))
            })
            .catch((err)=>{console.log(err)})
        }
    },[payload.conversation])

    function handleKeyPress(event){
        if (event.key === 'Enter') {
            event.preventDefault();
            handleinputsubmit();
        }
    }

    return(<div className='ChatbotWebView__rapper'>
        <div className='ChatbotWebView__header'>
            <div className='ChatbotWebView__header_icon'>
                <div title="Fullscreen" className='ChatbotWebView__header_fullscreenicon'>
                    <FullscreenIcon sx={{color: 'white'}}></FullscreenIcon>
                </div>
                <div title="Minimize" onClick={()=>webchatDispatch({type:'webchatisclosed'})} className='ChatbotWebView__header_minimizedicon'>
                    <MinimizeIcon sx={{color:'white'}}></MinimizeIcon>
                </div>
            </div>
            <div className='ChatbotWebView__header_title'>
                Chat with Us
            </div>
        </div>
        <div className='ChatbotWebView__content'>
            <ChatbotContent payload={payload.conversation}></ChatbotContent>
        </div>
        <div className='ChatbotWebView__input_container'>
            <div className='ChatbotWebView__input_container_inner2'>
                <textarea 
                value={query} 
                onChange={(e)=>{handleinputchange(e.target.value)}} 
                className='ChatbotWebView__input'  
                placeholder="Type your question here..."
                onKeyDown={handleKeyPress}
                ></textarea>
            </div>
            <div onClick={()=>{handleinputsubmit()}} className='ChatbotWebView__send_icon'>
                <SendIcon></SendIcon>
            </div>
        </div>
    </div>)
}

export default ChatbotWebView