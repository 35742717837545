import Chatbot from "../Chatbot/Chatbot"
import HomepageHearder from "./HomepageHeader"

function Homepage(){
    return(<div style={{backgroundColor:''}}>
        <HomepageHearder/>
        {/* header page with navigation options */}
    <Chatbot></Chatbot>
    </div>)
}

export default Homepage