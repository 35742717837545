import ChatbotContentAnswser from './ChatbotContentAnswser';
import ChatbotContentQuestion from './ChatbotContentQuestion';
import './chatbot.css';
import { WebchatContext } from './Chatbot'
import { useContext } from 'react';

function ChatbotContent({payload}){

    const {webchatState} = useContext(WebchatContext)

    return(<div className='ChatbotContent__wrapper'>
            {payload.map((e,key)=>{
                if(e.role === 'assistant'){
                    return <ChatbotContentAnswser keyid={key} body={e.content}/>
                }else if(e.role === 'user'){
                    return <ChatbotContentQuestion keyid={key} body={e.content}/>
                }else{
                    return <ChatbotContentAnswser body={webchatState.welcomeMessage}/>
                }
            })}
    </div>)
}

export default ChatbotContent